.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #5EA1DD;
}

.sidebarLogoWrapper {
  width: 100%;
  overflow-x: hidden;
}

img.sidebarLogo {
  width: 100%;
  max-width: 120px;
  max-height: 100%;
  min-width: 50px;
  z-index: 10000;
  margin-bottom: 5px;
  margin-top: 5px;
}

.mainAppWrapper > .MuiContainer-root .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.mainAppWrapper > .MuiContainer-root .MuiContainer-root .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;

}